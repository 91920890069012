import careForCaregiver from "../../images/video-thumbnails/Care-For-Caregiver.jpg"
import clinicalTrials from "../../images/video-thumbnails/Clinical-Trials.jpg"
import relapsedRefractory from "../../images/video-thumbnails/Relapsed-Refractory.jpg"
import treatmentClinicalTrials from "../../images/video-thumbnails/Treatment-Clinical-Trials.jpg"
import researchGroups from "../../images/video-thumbnails/Research-Groups.jpg"
import treatmentFollowUp from "../../images/video-thumbnails/Treatment-Follow-Up.jpg"
import copingDiagnosis from "../../images/video-thumbnails/Coping-Diagnosis.jpg"

export const videos = [
  {
    title: "Caring For The Caregiver",
    embedId: 'rANU9kgFCqk?si=yVIA4fKpyrAMn0r1',
    imageSrc: careForCaregiver,
    description: "Hear from a social worker and nurse practitioner on the importance of self-care during your child’s treatment journey."
  },
  {
    title: "Understanding Clinical Trials",
    embedId: 'sD3sUy4OVPY?si=vVMtXE6r_gab16gl',
    imageSrc: clinicalTrials,
    description: "Hear from a pediatric oncologist discussing some of the confusing topics around understanding clinical trials and interpreting their results."
  },
  {
    title: "Treatment And Clinical Trails",
    embedId: 'WACHq6nC_X8?si=N-9CzU4za_updLG3',
    imageSrc: treatmentClinicalTrials,
    description: "Learn why clinical trials are important in neuroblastoma and why they may be an option for your child."
  },
  {
    title: "Relapsed/Refractory Neuroblastoma",
    embedId: "nzJ4j8C5OMY?si=GsiG0oe7Ek8KoZFT",
    imageSrc: relapsedRefractory,
    description: "Hear from a pediatric oncologist and nurse practitioner as they discuss treatment and hope during this phase of your child’s journey."
  },
  {
    title: "Neuroblastoma Research Group",
    embedId: "0yQr5ch3GG0?si=NTQc0iej3R07U-er",
    imageSrc: researchGroups,
    description: "Learn more about the different research groups and the goals of their research."
  },
  {
    title: "Importance of After Treatment Follow-Ups",
    embedId: "J_Lt4K8Ma5Y?si=JXxWDcF32N9-vEMs",
    imageSrc: treatmentFollowUp,
    description: "Hear from a pediatric oncologist and nurse practitioner about why long-term follow-up is important for your child after they complete their treatment journey."
  },
  {
    title: "Coping with Neuroblastoma Diagnosis",
    embedId: "NYte1Rj8QPA?si=0DVi94G18SSt6sm4",
    imageSrc: copingDiagnosis,
    description: "Learn from a clinical social worker about helping your child and family cope with the diagnosis."
  },
]
